.promotions{

    min-width: 90.74vw;
    height: auto;
    background: #eee;
    border: 2px solid #000;
    border-radius: 1.25vw;
    position: fixed;
    left: 50%;
    transform: translateX(-50%) scale(0.8);
    top: 38.27vw;
    z-index: 20;
    padding: 4.81vw 7.87vw 7.5vw 7.87vw;
    box-sizing: border-box;
    opacity: 0;
    visibility: hidden;
    transition: 0.2s;

    @media (orientation: landscape){
        
        min-width: 59.37vw;
        padding: 2.76vw 3.33vw 4.06vw 3.33vw;
        top: 13.77vw;
        left: 31.78vw;
        
    }

    &[data-open="true"]{
        visibility: visible;
        opacity: 1;
        transform: translateX(-50%) scale(1);
    }

    h2{

        font-family: 'Knockout HTF49';
        font-size: 6.48vw;
        color: #000;
        margin-bottom: 4.16vw;
        margin-top: 0;

        @media (orientation: landscape){
        
            font-size: 3.64vw;
            margin-bottom: 2.23vw;
        }
    }

    .close{

        position: absolute;
        top: 5.07vw;
        right: 3.88vw;

        @media (orientation: landscape){
        
            top: 2.07vw;
            right: 2.08vw;
        }

        svg{

            width: 3.47vw;
            height: auto;

            @media (orientation: landscape){
        
                width: 1.95vw;
                height: auto;
            }
        }
    }

    .content{

        display: flex;
        flex-wrap: wrap;
        box-sizing: border-box;
        width: calc(100% + (0.92vw * 2));
        margin-left: -0.92vw;

        .store{

            width: calc(100% * 1/4);
            box-sizing: border-box;
            padding: 0.92vw;

            @media (orientation: landscape){
        
                width: calc(100% * 1/5);
            }
        }
    }
}
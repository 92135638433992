.button{

    width: 100%;
    height: 4.62vw;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #fff;
    border-radius: 2.31vw;
    border: 2px solid #000;
    box-sizing: border-box;
    padding: 0 2.59vw 0 2.40vw;
    margin-bottom: 1.11vw;
    transition: 0.5s;

    @media (orientation: landscape) {
        width: auto;
        height: 2.60vw;
        padding: 0 1.51vw 0 1.35vw;
        margin-bottom: 0vw;
        margin-right: 1.56vw;
 
    }

    &:last-of-type{
        margin-bottom: 0;
    }

    &[data-active="true"]{

        background-color: #000;

        span{
            color: #fff;
        }
        .icon{

            path, circle {

                fill: #fff;
            }

        }
    }

    .icon{

        width: 2.59vw;
        display: flex;
        justify-content: center;

        @media (orientation: landscape) {
            width: 1.48vw;
            margin-right: 0.72vw;
        }

        
        
        path, circle {

            fill: #000;
            transition: 0.5s;
        }

        img{

            width: 100%;
            height: auto;
        }

        .map{

            width: 1.66vw;
            height: auto;

            @media (orientation: landscape) {
                width: 0.97vw;
            }

            

        }

        .list{

            width: 100%;
            height: auto;
        }

    }

    span{

        width: 2.31vw;
        letter-spacing: 0.01em;
        line-height: 1;
        display: block;
        min-width: 12.40vw;
        text-align: center;
        transition: 0.5s;

        @media (orientation: landscape){
                    
            font-size: 1.30vw;
            width: 6.92vw;
            min-width: auto;
            
        }

    }
}
.container{

    position: fixed;
    top: 14.44vw;
    left: 0;
    width: 100%;
    height: calc(100% - 14.44vw);
    z-index: 10;
    box-sizing: border-box;
    padding: 3.70vw 4.16vw 0 4.16vw;
    transition: 0.5s;
    opacity: 0;
    visibility: hidden;
    transform: translateY(100%);

    @media (orientation: landscape){
        top: 5.72vw;
        height: calc(100% - 5.72vw);
        padding: 1.09vw 10.26vw 0 10.26vw;
        
    }

    &[data-active="true"]{
            
       top: 61vw;
       height: calc(100% - 61vw);
        
    }

    &[data-open="true"]{
        visibility: visible;
        opacity: 1;
        transform: translateY(0);
    }

    .overlay {

        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        background-color: rgba(0, 0, 0, .6);
        // backdrop-filter: blur(20px);
        // -webkit-backdrop-filter: blur(20px);
        z-index: -1;
       
    }

    .result{

        display: flex;
        flex-wrap: wrap;
        box-sizing: border-box;
        width: calc(100% + (0.92vw * 2));
        margin-left: -0.92vw;
        overflow: scroll;
        max-height: 55vh;

        .store{

            width: calc(100% * 1/4);
            box-sizing: border-box;
            padding: 0.92vw;

            @media (orientation: landscape){
        
                padding: 0.46vw;
                width: calc(100% * 1/7);
                
            }
        }

        &[data-active="true"]{
            
            max-height: 30vh;
             
        }
    }
    h2{
    
        font-family: 'Knockout HTF49';
        font-size: 8.33vw;
        font-weight: normal;
        line-height: 1;
        color: #fff;
        width: 100%;
        margin-bottom: 3.14vw;
        margin-top: 0;
    
        @media (orientation: landscape){
           
            font-size: 4.68vw;
            width: 16.30vw;
            display: inline-block;
            margin-bottom: 2.5vw;
        }
    
    }
}


.buttonClose{

    position: absolute;
    width: 4.53vw;
    height: 4.53vw;
    border: 2px solid #000;
    background-color: #eee;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 3.48vw;
    right: 4.81vw;
    z-index: 2;

    @media (orientation: landscape){
       
        width: 2.60vw;
        height: 2.60vw;
    }

    svg{

        width: 1.48vw;
        height: auto;

        @media (orientation: landscape){
       
            width: 1.11vw;
            height: auto;
        }
    }
}

.Keyboard{

    margin-bottom: 9.25vw;
    max-height: 94.44vw;

    @media (orientation: landscape){
       
        width: 67.2%;
        margin-bottom: 7.41vw;
        font-size: 1.6vw;
    }
}

.searchContainer{

    position: relative;
    margin-bottom: 4.16vw;

    @media (orientation: landscape){
       
        display: inline-block;
        margin-bottom: 2.5vw;
        transform: translateY(-1.35vw);
        width: 67.2%;
    }

    svg{

        position: absolute;
        width:  3.61vw;
        left: 0;
        top: 0;

        @media (orientation: landscape){
       
            width: 2.01vw;
            top: 0.52vw;
        }

        path{
            fill: #fff;
        }
    }

    input{

        border: none;
        background-color: transparent;
        font-size: 3.70vw;
        font-family: 'Knockout HTF32';
        color: #fff;
        border-bottom: 2px solid #fff;
        width: 58.33vw;
        padding-bottom: 2.31vw;
        padding-left: 7.03vw;
        outline: none;

        @media (orientation: landscape){
       
            width: 32.81vw;
            padding-bottom: 0.88vw;
            padding-left: 3.95vw;
            font-size: 2.08vw;
            line-height: 1;
        }        
    }
}


.container{

    width: 100%;
    box-sizing: border-box;
    position: relative;
    margin-bottom: 8.75vw;

    &[data-reverse="true"]{

        margin-bottom: 0;
    }

    @media (orientation: landscape){
        
        // width: 56.25%;
        width: 50.25%;
        margin-top: -2.60vw;
        margin-bottom: 0;
        
    }

    .where{

        width: 21.20vw;
        height: 4.62vw;
        display: flex;
        position: absolute;
        right: 4.11vw;
        top: 0;
        align-items: center;
        justify-content: space-between;
        background-color: #fff;
        border-radius: 2.31vw;
        border: 2px solid #000;
        box-sizing: border-box;
        padding: 0 1.20vw 0 1.57vw;
        margin-bottom: 1.11vw;
        transition: 0.5s;

        svg{

            @media (orientation: landscape){
        
                width: 1.51vw;
                height: 1.51vw;
                
            }
        }

        &[data-active="true"]{
            
            margin-bottom: 0;
            top: 2.77vw;
            
           
        }

        @media (orientation: landscape){
        
            width: 11.92vw;
            height: 2.60vw;
            padding: 0 0.88vw;
            right: -6.89vw;
            top: 17.41vw;
            
        }

        .textwhere{

            letter-spacing: 0.01em;
            line-height: 1;
            display: block;
            text-align: center;
            transition: 0.5s;
            font-size: 2.31vw;

            @media (orientation: landscape){
        
                font-size: 1.30vw;
                
            }
        } 
    }

    .scalator1{

        position: absolute;
        align-items:center;

        &[data-scalator="ground"]{
            top: 26.88vw;
            left: 22.37vw;
        }

        &[data-scalator="first"]{
            bottom: 22.88vw;
            right: 37.37vw;

            @media (orientation: landscape){
        
                bottom: 12.18vw;
                right: 18.27vw;  
            }

            bottom: 20.88vw;
            right: 31.37vw;
        }
 
        .instruction,.instruction2{

            border-radius: 1.89vw;
            border: 1.5px solid #000;
            background-color: #fff;
            font-size: 1.11vw;
            font-family: 'Knockout HTF52';
            justify-content: center;
            text-align: center;
            text-transform: uppercase;
            padding: 0.64vw 1.11vw 0.64vw 1.11vw ;

            @media (orientation: landscape){
        
                font-size: 0.62vw;
                padding: 0.52vw 0.88vw 0.52vw 0.88vw; 
            }
        }

        .instruction{

            width: 13.83vw;
            height: auto;
            margin-bottom: 0.74vw;

            animation-name: scalatorAnimation1;
            animation-duration: 3s;
            animation-fill-mode: forwards;
            z-index: 1;
            position: relative;

            @media (orientation: landscape){
        
                width: 7.90vw;
                height: auto;  
            }           
        }

        .instruction2{

            width: 9.87vw;
            height: auto;
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-left: 1.98vw;

            animation-name: scalatorAnimation2;
            animation-duration: 3s;
            animation-fill-mode: forwards;

            @media (orientation: landscape){
        
                width: 6.23vw;
                height: auto;
                margin-left: auto;
                margin-right: auto;                
            }

            svg{

                width: 3.85vw;
                height: auto;

                @media (orientation: landscape){
        
                    width: 2vw;
                    height: auto;
                    
                }
            }
        }
    }

    .buttonClose{

        position: absolute;
        width: 4.53vw;
        height: 4.53vw;
        border: 2px solid #000;
        background-color: #eee;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        top: 3.48vw;
        right: 4.81vw;
        z-index: 2;

        @media (orientation: landscape){
            width: 2.55vw;
            height: 2.55vw;
            top: 1.4vw;
            right: 0.81vw;
        }

        svg{

            width: 1.48vw;
            height: auto;

            @media (orientation: landscape){
                width: 0.98vw;
                height: auto;
            }
        }
    }

    .clear{

        width: 15.46vw;
        height: 4.62vw;
        display: flex;
        position: absolute;
        right: 4.11vw;
        top: 6vw;
        align-items: center;
        justify-content: space-between;
        background-color: #fff;
        border-radius: 2.31vw;
        border: 2px solid #000;
        box-sizing: border-box;
        padding: 0 3.51vw 0 2.22vw;
        margin-bottom: 1.11vw;
        transition: 0.5s;

        &[data-active=true]{
            top: 9vw;
        }

        @media (orientation: landscape){
            width: 7.8vw;
            height: 2.60vw;
            padding: 0.78vw 1.30vw 0.78vw 1.30vw;
                       
        }

        svg{
            @media (orientation: landscape){
                width: 0.93vw;
                
            }
        }
        
        .textclear{

            letter-spacing: 0.01em;
            line-height: 1;
            display: block;
            text-align: center;
            transition: 0.5s;
            font-size: 2.31vw;

            @media (orientation: landscape){
                font-size: 1.30vw;
                
            }
        }
    }

    .move{

        width: 21.20vw;
        height: 4.62vw;
        display: flex;
        position: absolute;
        left: 6.11vw;
        bottom: -6.20vw;
        align-items: center;
        justify-content: space-between;
        background-color: #fff;
        border-radius: 2.31vw;
        border: 2px solid #000;
        box-sizing: border-box;
        padding: 0 2.59vw 0 2.40vw;
        margin-bottom: -0.89vw;
        transition: 0.5s;
        z-index: 2;

        @media (orientation:landscape){
            display: none;
        }

        &[data-active="true"]{
            background: #000;
            color: #fff;
            padding: 0 4.59vw 0 2.40vw;
            bottom: -3.2vw;
            
            svg{

                path{
                    fill: #fff;
                }
            }
        }
       

        svg{
            width: 2.31vw;
            height: auto;
            display: flex;
            justify-content: center;
            
            path{
                transition: 0.5s;
            }
        }

        .textmove{

            letter-spacing: 0.01em;
            line-height: 1;
            display: block;
            text-align: center;
            transition: 0.5s;
            font-size: 2.31vw;
        }
    }

    .MapFrame {
        position: relative;
        width: 100%;
        height: 75.92vw;
        overflow: hidden;
        @media (orientation: landscape){
            height: 38.1vw;
        }
    }

    .mapa{

        text-align: center;
        width: 100%;
        height: 75.92vw;
        overflow: hidden;
        position: relative;

        &[data-active-store="true"]{
            transform: translateY(6.3vw);

            @media (orientation: landscape){
        
                transform: translateY(2.3vw);
            }
        }

        @media (orientation: landscape){
            
            // height: 42.70vw;
            height: 38.1vw;
            
        }

        svg{
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            @media (orientation: landscape){
                // top: -39.3px;
                // left: -52px;
                // transform: scale(0.89);
                top: -39.2px;
                left: -51.3px;
                transform: scale(0.894);
            }
        }

        .mapContainer{

            width: 100%;
            position: relative;
        }

        .market{

            position: absolute;
                        
            svg{

             
                width: 1.66vw;
                height: auto;
                transform: translate(-50%, -50%);

                @media (orientation: landscape){

                    width: 1.19vw;
                    height: auto;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                   
                }  

            }
        }

        .store{

            opacity: 0;
            transition: 0.1s;
            visibility: visible;

            &[data-visible="true"]{
                opacity: 1;
                transition: 0.5s;
            }

            &[data-click="false"]{
                visibility: hidden;
                opacity: 0;
            }

        }

        &[data-floor="food"]{
            transform: scale(1.7) translate(260px, 120px);
            + .zoomfade{
                opacity: 1;
                visibility: visible;
            }
            
            .mapContainer{
                // width: 210%;
                // height: 210%;
                // transform: translate(-2px, -323px);
                // width: 230%;
                // height: 230%;
                // transform: translate(-2px, -313px);

            }
        }

        &[data-where="true"]{

            + .zoomfade{
                opacity: 1;
                visibility: visible;
            }

            .mapContainer{
                width: 210%;
                height: 210%;
                // transform: translate(-2px, -323px);

            }

            .market svg {
                animation-name: marketAnimation;
                animation-duration: 2s;
                animation-fill-mode: forwards;
            }

        }

        + .zoomfade{

            opacity: 0;
            visibility: hidden;

            div{
                position: absolute;
                z-index: 1;
               

                &:nth-of-type(1){

                    background: linear-gradient(90deg, #f8ce3e 0%, rgba(248, 207, 58, 0) 100%);
                    top: 0;
                    left: 0;
                    width: 6.66vw;
                    height: 100%;
                    @media (orientation: landscape){
                        display: none;
                    }
                }

                &:nth-of-type(2){

                    background: linear-gradient(270deg, #f8ce3e 0%, rgba(248, 207, 58, 0) 100%);
                    top: 0;
                    right: 0;
                    width: 6.66vw;
                    height: 100%;
                }

                &:nth-of-type(3){

                    background: linear-gradient(180deg, #f8ce3e 0%, rgba(248, 207, 58, 0) 100%);
                    top: 0;
                    right: 0;
                    width: 100%;
                    height: 6.66vw;
                }

                &:nth-of-type(4){

                    background: linear-gradient(0deg, #f8ce3e 0%, rgba(248, 207, 58, 0) 100%);
                    right: 0;
                    bottom: 0;
                    width: 100%;
                    height: 6.66vw;
                    @media (orientation: landscape){
                        background: linear-gradient(#efbfb3 0%, rgba(241, 194, 163, 0) 100%);
                        transform: rotate(180deg);
                    }
                }
                
            }
        }

        img{
            width: 100%;
            height: 100%;    
        }
    }

    .infoContainer{

        top:0;
        left:50%;
        transform: translateX(-50%);
        text-transform: uppercase;
        position: absolute;
        width: 44.62vw;
        text-align: center;

        &[data-reverse=true]{
            top: 2.77vw;
        }

        @media (orientation: landscape){

            // width: 21.87vw;
            display: flex;
            align-items: center;
            width: auto;
            white-space: nowrap;
            top: 0.88vw;
        }    
           
        .title{
            
            font-size: 4.62vw;
            font-family: 'Knockout HTF49';

            @media (orientation: landscape){

                font-size: 2.60vw;
                padding-right: 1.71vw;
                border-right: 2px solid #000;
            }              
        }

        .cathorario{

            @media (orientation: landscape){

                padding-left: 1.71vw;    
            }  

            .category {

                font-size: 1.29vw;
                font-family: 'Knockout HTF49';
                text-align: center;
    
                @media (orientation: landscape){
    
                    font-size: 0.72vw;        
                }      
            }
    
            .horario{
    
                font-size: 1.29vw;
                font-family: 'Knockout HTF49';
                text-align: center;
    
                @media (orientation: landscape){
    
                    font-size: 0.72vw;        
                }     
            }
        }
    }

    .selector{

            display: flex;
            flex-wrap: wrap;
            box-sizing: border-box;
            width: 24.44vw;
            position: absolute;
            bottom: 4.62vw;
            right: 3.70vw;
            z-index: 1;

            @media (orientation: landscape){

                bottom: 4.62vw;
                right: -8.3vw;
                width: 13.75vw;               
            }  

        .item{

            opacity: 0.4;
            transition: 0.5s;
            position: relative;

            &[data-floor="food"]{
                width: 41.66%;
                order: 2;
            }

            &[data-floor="first"]{
                width: calc(100% - 41.66%);
                order: 1;

                .text{
                    margin-top: -0.25vw;
                    margin-bottom: 3.14vw;

                    @media (orientation: landscape){

                        margin-bottom: 1.56vw;
                    }
                }
            }

            &[data-floor="ground"]{
                width: 58.33%;
                order: 3;

                .text{
                    margin-top: -0.25vw;
                }
            }
            
            &.active{
                opacity: 1;
            }
            
            .icon{

                img{
                    width: 100%;
                }
                width: 12.03vw;
                margin-bottom: 1.5vw;
                margin-left: auto;
                margin-right: auto;
                right: 15.46vw;
                
                @media (orientation: landscape){

                    width: 6.77vw;
                    height: auto;
                    margin-bottom: 0.04vw;
                }                  
            }
    
            .text{
                
                font-family: 'Knockout HTF52';
                font-size: 2.31vw;
                color: #000;
                text-align: center;

                @media (orientation: landscape){
                    font-size: 1.30vw;
                }               
            }

            .iconmini{

                img{
                    width: 100%;
                }
                width: 6.01vw;
                margin-bottom: 1.5vw;
                margin-left: auto;
                margin-right: auto;
                transform: translateY(1.01vw);

                @media (orientation: landscape){
                    width: 3.38vw;
                    height: auto;
                    margin-bottom: -0.21vw;
                    transform: translateY(0);
                }                  
            }

            .textfood{
                
                font-family: 'Knockout HTF52';
                font-size: 2.31vw;
                color: #000;
                text-align: center;
                margin-top: 2.2vw;

                @media (orientation: landscape){

                    font-size: 1.30vw;
                    margin-top: 0;
                }               
            }
        }        
    }
}

@keyframes marketAnimation {

    0% {width: 7.42vw;}
    50% {width: 7.42vw;}
    100% {width: 3.8vw;}
}

@keyframes scalatorAnimation1 {

    0% {transform: translateY(2.31vw);}
    50% {transform: translateY(2.31vw);}
    100% {transform: translateY(0);}
}

@keyframes scalatorAnimation2 {

    0% {transform: translateY(-2.31vw);}
    50% {transform: translateY(-2.31vw);}
    100% {transform: translateY(0);}
}



.store{

    display: flex;
    flex-direction: column;
    height: 100%;

    .img{
        width: 100%;
        height: 11.48vw;
        position: relative;

        @media (orientation: landscape){
       
            height: 6.04vw;
        }

        .ad{
            position: absolute;
            display: flex;
            width: auto;
            height: 2.40vw;
            background: #eee;
            border: 0.5px solid #000;
            padding: 0 1.48vw;
            border-radius: 1.25vw;
            align-items: center;
            top: 0.92vw;
            left: 1.48vw;

            @media (orientation: landscape){
       
                height: 1.26vw;
                padding: 0 0.88vw;
                top: 0.98vw;
                left: 0.41vw;
            }

            span{

                font-family: 'Knockout HTF52';
                font-size: 1.20vw;

                @media (orientation: landscape){
       
                    font-size: 0.62vw;
                }
            }
        }

        img{

            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center;
        }
    }

    .info{

        background-color: #fff;
        width: 100%;
        height: calc(100% - 11.48vw);
        box-sizing: border-box;
        padding: 1.66vw 0.92vw 1.01vw 0.92vw;
        text-align: center;
        display: flex;
        flex-direction: column;
        align-items: center;

        @media (orientation: landscape){
       
            height: calc(100% - 6.04vw);
            padding: 1.04vw 0.26vw 0.52vw 0.26vw;
        }

        .title{
            font-family: 'Knockout HTF49';
            font-size: 2.22vw;
            text-transform: uppercase;
            margin-bottom: 1.11vw;

            @media (orientation: landscape){
       
                font-size: 1.25vw;
                margin-bottom: 0.67vw;
            }
        }

        .category{

            font-family: 'Knockout HTF29'; // es la 29
            font-size: 1.29vw;
            text-transform: uppercase;
            margin-bottom: 1.11vw;

            @media (orientation: landscape){
       
                font-size: 0.62vw;
                margin-bottom: 0.88vw;
            }
        }

        .description,
        .promodescription{
            font-family: 'Knockout HTF29';
            font-size: 1.29vw;
            margin-bottom: 0.55vw;

            @media (orientation: landscape){
       
                font-size: 0.62vw;
                margin-bottom: 0.36vw;                
            }
        }

        .promodescription{
            margin-bottom: 1.85vw;
        }

        .location{

            width: auto;
            height: 2.5vw;
            border-radius: 1.25vw;
            border: 1px solid #000;
            display: inline-flex;
            align-items: center;
            padding: 0 0.64vw 0 1.11vw;

            @media (orientation: landscape){
       
                height: 1.30vw;
                padding: 0 0.93vw 0 0.62vw;
            }
            
            svg{

                width: 1.03vw;
                height: auto;
                margin-right: 0.46vw;

                @media (orientation: landscape){
       
                    width: 0.54vw;        
                }                
            }

            span{

                font-family: 'Knockout HTF29';
                font-size: 1.11vw;

                @media (orientation: landscape){
       
                    font-size: 0.52vw;
                }
            }
        }

        .time{

            font-family: 'Knockout HTF29';
            font-size: 1.29vw;
            margin-top: auto;
            margin-bottom: 0.92vw;

            @media (orientation: landscape){
       
                font-size: 0.62vw;
                margin-bottom: 0.67vw;                
            }
        }
    }
}

.Container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #EEEEEE;
    z-index: 30;
    visibility: visible;
    opacity: 1;
    &.Loaded {
        visibility: hidden;
        opacity: 0;
        transition: .2s;
        transition-delay: .5s;
    }
    .Loader {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        display: inline-block;
        width: 80px;
        height: 80px;
        div {
            position: absolute;
            border: 4px solid #000;
            opacity: 1;
            border-radius: 50%;
            animation: loader-anim 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
            &:nth-child(2) {
                animation-delay: -0.5s;
            }
        }
    }
    .ImageWrapper {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
        max-width: 300px;
        @media screen and (max-width: 767px) {
            max-width: 240px;
        }
    }
    img {
        width: 100%;
        -webkit-animation: scale-up-center 0.6s cubic-bezier(0.390, 0.575, 0.565, 1.000) infinite alternate forwards;
	            animation: scale-up-center 0.6s cubic-bezier(0.390, 0.575, 0.565, 1.000) infinite alternate forwards;
    }
}

@-webkit-keyframes scale-up-center {
    0% {
        -webkit-transform: scale(.9);
                transform: scale(.9);
        opacity: 1;
        
    }
    100% {
        -webkit-transform: scale(1);
                transform: scale(1);
        opacity: .4;
    }
}
@keyframes scale-up-center {
    0% {
        -webkit-transform: scale(.9);
                transform: scale(.9);
        opacity: 1;
    }
    100% {
        -webkit-transform: scale(1);
                transform: scale(1);
        opacity: .4;
    }
} 

@keyframes loader-anim {
    0% {
        top: 36px;
        left: 36px;
        width: 0;
        height: 0;
        opacity: 1;
    }
    100% {
        top: 0px;
        left: 0px;
        width: 72px;
        height: 72px;
        opacity: 0;
    }
}

 /* Fonts */

@font-face {
  
    font-family: 'Knockout HTF26';
    src: url('./fonts/Knockout-HTF26-JuniorFlyweight.eot');
    src: url('./fonts/Knockout-HTF26-JuniorFlyweight.eot?#iefix') format('embedded-opentype'),
        url('./fonts/Knockout-HTF26-JuniorFlyweight.woff2') format('woff2'),
        url('./fonts/Knockout-HTF26-JuniorFlyweight.woff') format('woff'),
        url('./fonts/Knockout-HTF26-JuniorFlyweight.ttf') format('truetype'),
        url('./fonts/Knockout-HTF26-JuniorFlyweight.svg#Knockout-HTF26-JuniorFlyweight') format('svg');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {

    font-family: 'Knockout HTF28';
    src: url('./fonts/Knockout-HTF28-JuniorFeatherwt.eot');
    src: url('./fonts/Knockout-HTF28-JuniorFeatherwt.eot?#iefix') format('embedded-opentype'),
        url('./fonts/Knockout-HTF28-JuniorFeatherwt.woff2') format('woff2'),
        url('./fonts/Knockout-HTF28-JuniorFeatherwt.woff') format('woff'),
        url('./fonts/Knockout-HTF28-JuniorFeatherwt.ttf') format('truetype'),
        url('./fonts/Knockout-HTF28-JuniorFeatherwt.svg#Knockout-HTF28-JuniorFeatherwt') format('svg');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {

    font-family: 'Knockout HTF29';
    src: url('./fonts/Knockout-HTF29-JuniorLiteweight.eot');
    src: url('./fonts/Knockout-HTF29-JuniorLiteweight.eot?#iefix') format('embedded-opentype'),
        url('./fonts/Knockout-HTF29-JuniorLiteweight.woff2') format('woff2'),
        url('./fonts/Knockout-HTF29-JuniorLiteweight.woff') format('woff'),
        url('./fonts/Knockout-HTF29-JuniorLiteweight.ttf') format('truetype'),
        url('./fonts/Knockout-HTF29-JuniorLiteweight.svg#Knockout-HTF29-JuniorLiteweight') format('svg');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {

    font-family: 'Knockout HTF52';
    src: url('./fonts/Knockout-HTF52-Cruiserweight.eot');
    src: url('./fonts/Knockout-HTF52-Cruiserweight.eot?#iefix') format('embedded-opentype'),
        url('./fonts/Knockout-HTF52-Cruiserweight.woff2') format('woff2'),
        url('./fonts/Knockout-HTF52-Cruiserweight.woff') format('woff'),
        url('./fonts/Knockout-HTF52-Cruiserweight.ttf') format('truetype'),
        url('./fonts/Knockout-HTF52-Cruiserweight.svg#Knockout-HTF52-Cruiserweight') format('svg');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {

    font-family: 'Knockout HTF49';
    src: url('./fonts/Knockout-HTF49-Liteweight.eot');
    src: url('./fonts/Knockout-HTF49-Liteweight.eot?#iefix') format('embedded-opentype'),
        url('./fonts/Knockout-HTF49-Liteweight.woff2') format('woff2'),
        url('./fonts/Knockout-HTF49-Liteweight.woff') format('woff'),
        url('./fonts/Knockout-HTF49-Liteweight.ttf') format('truetype'),
        url('./fonts/Knockout-HTF49-Liteweight.svg#Knockout-HTF49-Liteweight') format('svg');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {

    font-family: 'Knockout HTF32';
    src: url('./fonts/Knockout-HTF32-JuniorCruiserwt.eot');
    src: url('./fonts/Knockout-HTF32-JuniorCruiserwt.eot?#iefix') format('embedded-opentype'),
        url('./fonts/Knockout-HTF32-JuniorCruiserwt.woff2') format('woff2'),
        url('./fonts/Knockout-HTF32-JuniorCruiserwt.woff') format('woff'),
        url('./fonts/Knockout-HTF32-JuniorCruiserwt.ttf') format('truetype'),
        url('./fonts/Knockout-HTF32-JuniorCruiserwt.svg#Knockout-HTF32-JuniorCruiserwt') format('svg');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {

    font-family: 'Knockout HTF93';
    src: url('./fonts/Knockout-HTF93-UltmtHeviwt.eot');
    src: url('./fonts/Knockout-HTF93-UltmtHeviwt.eot?#iefix') format('embedded-opentype'),
        url('./fonts/Knockout-HTF93-UltmtHeviwt.woff2') format('woff2'),
        url('./fonts/Knockout-HTF93-UltmtHeviwt.woff') format('woff'),
        url('./fonts/Knockout-HTF93-UltmtHeviwt.ttf') format('truetype'),
        url('./fonts/Knockout-HTF93-UltmtHeviwt.svg#Knockout-HTF93-UltmtHeviwt') format('svg');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {

    font-family: 'Knockout HTF48';
    src: url('./fonts/Knockout-HTF48-Featherweight.eot');
    src: url('./fonts/Knockout-HTF48-Featherweight.eot?#iefix') format('embedded-opentype'),
        url('./fonts/Knockout-HTF48-Featherweight.woff2') format('woff2'),
        url('./fonts/Knockout-HTF48-Featherweight.woff') format('woff'),
        url('./fonts/Knockout-HTF48-Featherweight.ttf') format('truetype'),
        url('./fonts/Knockout-HTF48-Featherweight.svg#Knockout-HTF48-Featherweight') format('svg');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@import "slick-carousel/slick/slick.css";
@import "react-simple-keyboard/build/css/index.css";

body {

  margin: 0;
  padding: 0;
  font-size: 2.31vw;
  font-family: 'Knockout HTF52';
  background-image: url(./assets/Fondo.svg);
  background-size: cover;
  background-position: top;
  background-repeat: no-repeat;
  color: #000;  

}

@media (orientation: landscape){ 
    
    body{

        background-image: url(./assets/Fondo_landscape.svg);

    }
}

.simple-keyboard.myTheme1 {
    background-color: transparent;
    border-radius: 0;
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
  }
  
  .simple-keyboard.myTheme1 .hg-button {
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(0, 0, 0, 1);
    color: white;
  }
  
  .simple-keyboard.myTheme1 .hg-button:active {
    background: #1c4995;
    color: white;
  }
  
  #root .simple-keyboard.myTheme1 + .simple-keyboard-preview {
    background: #1c4995;
  }

.slick-slide {
    z-index: 0;
}
.slick-slide.slick-current {
    z-index: 1;  
}

* {
    -ms-overflow-style: none;
    scrollbar-width: none;
}

*::-webkit-scrollbar {
    display: none;
}
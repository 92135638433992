.container{

    width: 100%;
    box-sizing: border-box;
    padding: 2.77vw 4.62vw 2.77vw 4.62vw;

    @media (orientation: landscape){
        
        padding: 1.45vw 2.083vw 1.97vw 2.083vw;

    }

    &[data-active="true"]{

        padding-bottom: 0;
    }

    .content{

        display: flex;
        flex-wrap: wrap;
        padding-bottom: calc(3.4vw - 0.92vw );
        border-bottom: 2px solid #fff;

        @media (orientation: landscape){
    
            align-items: center;
            border-bottom: none;
            padding-bottom: 0;
        }

        h1{
            font-family: 'Knockout HTF49';
            font-size: 9.25vw;
            font-weight: normal;
            line-height: 1;
            color: #fff;
            width: 100%;
            margin-top: 0;
            margin-bottom: 0;
    
            @media (orientation: landscape){
    
               font-size: 4.68vw;
               width: 25.27%;
            }
        }

        .col1{
            
            width: 76.68%;

            @media (orientation: landscape){
                width: calc(100% - 25.27%);
                display: flex;
            }
        }

        .col2{

            width: calc(100% - 76.68%);

            @media (orientation: landscape){
                width: 100%;
                display: flex;
                justify-content: flex-start;
                border-top: 2px solid #fff;
                padding-top: 1.04vw;
                margin-top: 0.72vw;
                position: relative;
                z-index: 2;   
            }

        }

        .search{

            @media (orientation: landscape){
                width: 42.18%;               
            }
        }

        .searchContainer{

            position: relative;
            margin-bottom: 3.4vw;
            margin-top: 2.5vw;

            @media (orientation: landscape){
                    
                margin-bottom: 0vw;
                margin-top: 0.72vw;
                max-width: 26.35vw;               
            }

            svg{

                position: absolute;
                width: 3.51vw;
                left: 0;
                top: 0;

                @media (orientation: landscape){
                    
                    width: 2.01vw;
                    left: 0;
                    top: 0.20vw;                    
                }
            }

            input{

                border: none;
                background-color: transparent;
                font-size: 3.70vw;
                font-family: 'Knockout HTF28';
                color: #000;
                border-bottom: 2px solid #000;
                width: 58.33vw;
                padding-bottom: 1.6vw;
                padding-left: 7.03vw;
                outline: none;

                &::placeholder{

                    opacity: 0.25;
                }

                @media (orientation: landscape){
                    
                    width: 100%;
                    font-size: 2.08vw;
                    padding-bottom: 0.6vw;
                    padding-left: 4.03vw;
                    box-sizing: border-box;                
                }
            }
        }
    }

    .categories{

        @media (orientation: landscape){
                    
            width: calc(100% - 42.18%);
            display: flex;
            flex-wrap: wrap;            
        }
    }

    .category{
        font-family: 'Knockout HTF52';
        font-size: 1.57vw;
        text-transform: uppercase;
        height: 3.33vw;
        border-radius: 1.66vw;
        background: #eee;
        justify-content: center;
        align-items: center;
        display: inline-flex;
        padding: 0 2.31vw;
        margin-right: 1.5vw;
        margin-bottom: 0.92vw;

        span{
            display: inline-block;
        }

        &[data-active="true"]{
            background: #000;
            color: #fff;
        }

        @media (orientation: landscape){
                    
            font-size: 0.88vw;
            padding: 0 0.78vw;
            height: 1.87vw;
            margin-right: 0.52vw;
            margin-bottom: 0.52vw;
        }
    }
}


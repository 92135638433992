
.slider{
    
    width: 100%;
    box-sizing: border-box;
    padding: 0 6.11vw;

    @media (orientation: landscape){

        padding: 0;
    } 

    &[data-active="true"]{

        margin-top: 6.48vw;
        margin-bottom: 2.48vw;
    }

    .item{

        width: 100%;
        height: 37.037vw;
        border: 3px solid #000;
        position: relative;
        box-sizing: border-box;
        &::after{
            content: "";
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: linear-gradient(270deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.4) 100%);
            z-index: 1;
        }
        
        @media (orientation: landscape){

            width: 35.98vw;
            height: 14.73vw;
        }  

        img,video{

            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center;
            
        }

        .text{
            
            position: absolute;
            top: 3.70vw;
            left: 2.77vw;
            font-family: 'Knockout HTF49';
            font-size: 5.55vw;
            color: #fff;
            text-transform: uppercase;
            z-index: 2;

            @media (orientation: landscape){

                font-size: 2.13vw;
                top: 1.45vw;
                left: 1.19vw;
            } 
        }
    } 
    
    .button{
        
        font-family: 'Knockout HTF52';
        font-size: 1.57vw;
        height: 3.33vw;
        border-radius: 1.66vw;
        background: #eee;
        justify-content: center;
        align-items: center;
        display: inline-flex;
        padding: 0 2.31vw;
        margin-right: 1.5vw;
        margin-bottom: 0.92vw;
        position: absolute;
        top: 3.70vw;
        right: 2.77vw;
        z-index: 2;

        span{
            display: inline-block;
        }

        @media (orientation: landscape){

            font-size: 0.88vw;
            padding: 0 1.77vw;
            height: 1.8vw;
            top: 1.45vw;
            right: 1.19vw;
            margin-right: 0;
            
            
        } 

        
    }
}
.container{

    width: 100%;
    box-sizing: border-box;
    padding: 6.6vw 4.62vw 7.12vw 4.62vw;
    position: relative;

    @media (orientation: landscape){
        
        padding: 0.52vw 2.55vw 5.52vw 2.08vw;
        width: 61.92%;
    }

    .paginator{

        position: absolute;
        top: 0;
        right: 4.62vw;
        bottom: 1.20vw;
        display: flex;
        height: 0;

        @media (orientation: landscape){
        
            right: 1.62vw;
            bottom: 1.20vw;
            top: -2.08vw;
        }

        .prev{

            margin-right: 1.48vw;
            transition: 0.5s;

            @media (orientation: landscape){
        
                margin-right: -1.5vw;
            }

            &[data-enable="false"]{
                opacity: 0.6;
            }

            svg{
                width: 5.83vw;
            }
        }

        .next{

            transition: 0.5s;

            &[data-enable="false"]{
                opacity: 0.6;
            }

            svg{
                width: 5.83vw;
            }
        }
    }

    .content{

        display: flex;
        flex-wrap: wrap;
        width: calc(100% + (0.46vw * 2));
        margin-left: -0.46vw;
        max-height: 110.18vw;
        overflow: scroll;
    }

    .store{

        padding: 0.46vw;
        width: calc(100% * 1/5);
        box-sizing: border-box;
        display: flex;
        flex-direction: column;

        @media (orientation: landscape){
        
             width: calc(100% * 1/6);
        }
    }
}
